import React, { useEffect } from "react";

const useWisePopsIntegration = () => {
    useEffect(() => {
        // Create the script element
        const script = document.createElement("script");
        script.setAttribute("data-cfasync", "false");
        script.defer = true;
        script.src = "https://wisepops.net/loader.js?v=3&h=Gb3ksDZv8K";

        // Append the script to the body
        document.body.appendChild(script);

        // Cleanup function to remove the script if the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return null; // This component doesn't render anything
};

export default useWisePopsIntegration;
